@media (max-width: 480px) {
    .admin-page{
        padding: 1rem;
    }
    .head-btn{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .head-btn .btn{
        margin-left: 0px !important;
        margin-top: 0.5rem;
        width: 100%c;
    }
}

@media (max-width: 767px) {

    .logo-top{

        display: none;

    }

    .main-title h1{

        font-size: 2rem;
        text-align: center;

    }

    .large-h2 {
        font-size: 3rem;
    }

    .about-p p,.founders p {
        font-size: 1rem;
    }

    .partners-logo img{

        width: 100px;
        padding: 20px 0;
    }

    .last-logo{

        display: none;

    }

    .egg{

        width: 120px;

    }

    .celestial-angels{

        justify-content: center !important;

    }

    .section1 {

        height: 85vh;

    }

    .opensea p{

        margin-bottom: 20px !important;

    }

    .section1{
        background: 25% 100% / contain no-repeat url(../images/mobile-home-characters.png), center / 100% 100% no-repeat url(../images/mobile-hero-bg.png);
    }
    .section-title{
        text-align: center;
    }
    .about{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logo-guardian,.logo-fallen,.logo-pixel{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logo-guardian div div,.logo-fallen div div,.logo-pixel div div{
       justify-content: center !important;
    }
    .logo-guardian div p,.logo-fallen div p,.logo-pixel div p{
        text-align: left;
    }
    .hide-mobile{
        display: none;
    }
    .collection.text-center{
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 0;
        right: 0;
    }
    .faqs{
        text-align: center;
    }
    .faqs p{
        text-align: left;
    }
    .faqs h1{
        text-align: center;
    }
    .mobile-order-2{
        order: 2;
    }
    .top-social{
        padding-left: 20px;
        padding-top: 1rem;
        width: 100%;
    }
    .footer-social{
        justify-content: flex-end !important;

    }
    .join-text{
        margin-bottom: 1rem !important;
    }
    #footer-section .footer-section{
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
    .mobile-hide{
        display: none;
    }
    .hide-pc{
        display: block;
    }
    .mint-box{
        width: 90%;
    }
    .mint-arrow-section{
        bottom: 20px;
        display: flex;
        height: calc(100vh - 100px);
      }
    .custom-drop-body{
        position: relative;
        border: none;
        width: 100%;
        margin-left: 1rem;
    }
    .custom-drop-body a{
        margin-bottom: 0;
      }
}

@media (max-width: 768px) {


    .top-social{
        position: sticky;
        bottom: 10px;
    }
    .custom-dropdown {
        margin-top: 0.5rem;
    }
    .sidenav .menu-list{

        width: 70%;

    }

    .sidenav .menu-list a {
        font-size: 1.5rem;
    }
    
    .social{

        justify-content: center;

    }
    .slick-slide img{
        height: 300px;
    }
   
    .collection-section h1{

        margin: 20px 0;
    }

    .footer-first{

        flex-direction: column;
        align-items: center;
        
        
    }
  

}

@media (max-width: 991px) and (min-width: 768px) {

    .main-title h1{

        font-size: 3rem;
        text-align: center;

    }

    .large-h2 {
        font-size: 3rem;
    }

    .about-p p,.founders p {
        font-size: 1.5rem;
    }

    .partners-logo img{

        width: 100px;
        padding: 20px 0;
    }


}

@media(max-width:991px){

   .logo {

    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    text-decoration: none;
    color: var(--white);

   }

   .closebtn{

    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    text-decoration: none;
    color: var(--white);
    padding: 0 30px !important;
    display: block;
  
  }

   .opennav{

    display: block;
  
  }

  .sidenav{
    height: 100%;
    width: 0;
    position: fixed;
    background-color: #5b5b5b;
    background-image: url(../images/sidebar.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: flex-start;
    padding-top: 80px;
  }

    /* .custom-nav .navbar-collapse{

        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        background-color: var(--black);

    } */

    .custom-nav .navbar-nav{

        align-items: flex-start;

    }

    .custom-nav .navbar-nav a{

        padding: 10px 20px;

    }

    .custom-nav .top-social{

        /* position: absolute; */
        margin-bottom: 10px;
        margin-right: 5px;

    }

    .last-logo img{

        display: none;

    }

    .custom-btn{

        font-size: 12px;
        padding:5px 10px !important;

    }

    .social li {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        border: 2px solid var(--primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social li a {
        font-size: 12px;
    }

    .team-box{

        margin-bottom: 30px;

    }

}

@media (max-width: 1023px) and (min-width: 992px) {

    .large-h2 {
        font-size: 4rem;
    
    }

    .about-p p {
        font-size: 1.5rem;
    }

    .custom-btn{

        font-size: 12px;
        padding:5px 10px !important;

    }

    .social li {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        border: 2px solid var(--primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social li a {
        font-size: 12px;
    }

}

@media (max-width: 1280px) and (min-width: 1024px) {


    .large-h2 {
        font-size: 4rem;
    
    }

    .about-p p {
        font-size: 1.5rem;
    }

}

@media (max-width: 1400px) and (min-width: 1200px) {

    .custom-btn span {
        font-size: .8rem;
    }

}

@media(min-width: 1440px) {
}